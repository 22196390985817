<template>
  <b-modal :active="showRace" :on-cancel="close" scrollable>
    <div class="modal-card-container new-race-height">
      <div class="modal-card">
        <div class="modal-card-head">
          <p class="modal-card-title is-flex-shrink-1">New Race</p>
        </div>
        <section v-if="loaded" class="modal-card-body">
          <b-field label="Name">
            <b-input v-model="race_name">
            </b-input>
          </b-field>
          <div class="select-grid mb-3">
              <p class="subtitle is-4 m-0 pr-2 select-text">Select tracks from </p>
              <b-datepicker v-model="date" size="is-small"
                :selectable-dates="selectableDates"
                :min-date="minDate"
                :max-date="maxDate"
                :mobile-native="false"
              ></b-datepicker>
          </div>
          <b-checkbox v-for="track in dayTracks" :key="track.id"
            v-model="selectedTracks"
            :native-value="track.id"
          >
            {{ track.id }} - {{ track.full_name }}, {{ track.boat_name }}, 
            {{track.start_time | moment("h:mm a")}} - {{track.end_time | moment("h:mm a")}} 
          </b-checkbox>
          <div class="is-flex-direction-column is-justify-content-center" v-if="dayTracks.length == 0">
            <h2 class="title is-2">No Tracks</h2>
            <p>Please select another date.</p>
          </div>
        </section>
        <section v-else class="modal-card-body">
          <div class="center-grid">
            <i class="fa fa-spinner fa-spin fa-3x"></i>
          </div>
        </section>

        <footer class="modal-card-foot">
          <div class="footer-container">
            <b-button type="is-primary" @click="save"> Save </b-button>
            <b-button type="is-danger" @click="close"> Cancel </b-button>
            <p style="color: red;" v-if="showError">{{errors.save}}</p>
          </div>
        </footer>
      </div>
    </div>
  </b-modal>
  
</template>

<script>
import { mapGetters } from "vuex"
import { required, minLength } from "vuelidate/lib/validators"
import api from "@/services/api/races"
import trackApi from "@/services/api/tracks.js"
import moment from "moment"

export default {
  name: "RaceForm",
  props: ["tracks", "races", "showRace"],
  validations: {
    boats: { required, minLength: minLength(2) },
  },
  data() {
    return {
      // tracks from the race day
      dayTracks: [],
      selectedTracks: [],
      errors: {},
      showError: false,
      race_name: "",
      date: null,
    }
  },
  computed: {
    ...mapGetters({
      raceDates: "tracks/getDates",
    }),
    loaded() {
      return this.races[0] != undefined
    },
    racePayload() {
      return {
        track: this.selectedTracks,
        name: this.race_name,
      }
    },
    dayLabel() {
      return `Tracks from ${moment(this.date).format("M/D/YYYY")}`
    },
    selectableDates() {
      return this.raceDates.map((dateString) => moment(dateString).toDate())
    },
    minDate() {
      try {
        return this.selectableDates[this.selectableDates.length - 1]
      } catch (error) {
        return null
      }
    },
    maxDate() {
      try {
        return this.selectableDates[0]
      } catch (error) {
        return null
      }
    }
  },
  methods: {
    setDayTracks() {
      trackApi.getDayTracks('', 'all', moment(this.date).format("YYYY-MM-DD"))
        .then((res) => {
          this.dayTracks = res.data.results
        })
    },
    setStartDate() {
      if(this.showRace && this.selectableDates.length > 0) {
        this.date = this.selectableDates[0]
      }
    },
    setRaceName() {
      if(this.races[0]) {
        this.race_name = "Race " + parseInt(this.races[0].id + 1)
      }
    },
    close() {
      this.date = null
      this.errors = {}
      this.$emit("race-created")
    },
    save() {
      if (this.selectedTracks.length < 2) {
        this.errors.save = "Please select at least 2 tracks."
        this.showError = true
        return
      }
      api.createRace(this.racePayload)
        .then((res) => {
          this.$store.dispatch("races/getRaces")
          this.selectedTracks = []
          this.close()
          this.$router.push({
            path: `/races/${res.data.id}`,
          })
        })
    },
  },
  watch: {
    showRace(val) {
      if(val) {
        this.setStartDate()
        this.setRaceName()
      }
      if(val && this.dayTracks.length === 0) {
        // if modal is shown and no tracks yet
        this.setDayTracks()
      }
    },
    date(val) {
      if(val != null) {
        this.setDayTracks()
      }
      this.selectedTracks = []
    },
    selectedTracks(newVal, oldVal) {
      if (newVal.length >= 2) {
        this.errors = {}
        this.showError = false
      }
    },
    selectableDates(val) {
      this.setStartDate() 
    },
    races(val) {
      this.setRaceName()
    }
  },
}
</script>

<style scoped>
.select-text {
  width: 200px;
}
.select-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
}
.center-grid {
  display: grid;
  place-items: center;
  height: 100%;
}
.new-race-height {
  height: 95vh;
  margin-inline: 1rem;
}

@media only screen and (max-width: 600px) {
  .new-race-height {
    height: 65vh;
  }
}
</style>
